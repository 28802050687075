import { ref, computed, watch } from '@vue/composition-api'
import store from '@/store'

export default function usePaymentListAddNew(props, clearForm, emit) {
  // ------------------------------------------------
  // paymentLocal
  // ------------------------------------------------
  const paymentLocal = ref(JSON.parse(JSON.stringify(props.paymentData.value)))
  const resetPaymentLocal = () => {
    paymentLocal.value = JSON.parse(JSON.stringify(props.paymentData.value))
  }
  watch(props.paymentData, () => {
    resetPaymentLocal()
  })

  // ------------------------------------------------
  // isAddNewPaymentSidebarActive
  // * Clear form if sidebar is closed
  // ------------------------------------------------
  watch(props.isAddNewPaymentSidebarActive, val => {
    // ? Don't reset event till transition is finished
    if (!val) {
      setTimeout(() => {
        clearForm.value()
      }, 350)
    }
  })
  

  const onSubmit = () => {
    const paymentData = JSON.parse(JSON.stringify(paymentLocal))

    // * If event has id => Edit Event
    // Emit event for add/update event
    if (props.paymentData.value.id) emit('update-payment', paymentData.value)
    else emit('add-payment', paymentData.value)

    // Close sidebar
    // emit('update:is-add-new-payment-sidebar-active', false)
  }

  const groupsOptions = computed(() => store.state.calendar.groups)


  /* eslint-enable global-require */

  return {
    paymentLocal,
    resetPaymentLocal,
    onSubmit,
    groupsOptions
  }
}
