<template>

    <div>
  
      <payment-list-add-new
        :is-add-new-payment-sidebar-active.sync="isAddNewPaymentSidebarActive"
        :role-options="roleOptions"
        :plan-options="planOptions"
        :type-options="typeOptions"
        :category-options="getAllPaymentCategories"
        :payment-options="paymentOptions"
        :clear-payment-data="clearPaymentData"
        :paymentData="paymentData"
        @add-payment="addPayment"
        @update-payment="updatePayment"
      />
  
      <!-- Filters -->
      <payments-list-filters
        :type-filter.sync="typeFilter"
        :payment-filter.sync="paymentFilter"
        :status-filter.sync="statusFilter"
        :month-filter.sync="monthFilter"
        :category-filter.sync="categoryFilter"
        :range-filter.sync="rangeFilter"
        :type-options="typeOptions"
        :payment-options="paymentOptions"
        :month-options="monthOptions"
        :status-options="statusOptions"
        :category-options="getAllPaymentCategories"
        :range-options="rangeOptions"
      />
  
      <!-- Table Container Card -->
      <b-card
        no-body
        class="mb-0"
      >
  
        <div class="m-2">
  
          <!-- Table Top -->
          <b-row>
  
            <!-- Per Page -->
            <b-col
              cols="12"
              md="2"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>Show</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>entries</label>
            </b-col>
  
            <!-- Search -->
            <b-col
              cols="12"
              md="10"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-datepicker
                  v-model="fromDate"
                  placeholder="Date From"
                  class="mr-1"
                  today-button
                  reset-button
                  close-button
                ></b-form-datepicker>
                
                <b-form-datepicker
                  v-model="toDate"
                  placeholder="Date To"
                  class="mr-1"
                  today-button
                  reset-button
                  close-button
                ></b-form-datepicker>

                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Search..."
                />
                <!-- <b-button
                  variant="primary"
                  tag="router-link"
                  :to="{ name: 'apps-payments-add' }"
                  class="mr-2"
                >
                  <span class="text-nowrap">Add Payment</span>
                </b-button> -->
                <b-button
                  variant="primary"
                  class="mr-1"
                  @click="isAddNewPaymentSidebarActive = true"
                >
                  <span class="text-nowrap">Add Payment</span>
                </b-button>
                <!-- <b-button
                  variant="outline-primary"
                  class="mr-1"
                  @click="genPaymentReport"
                >
                  <span class="text-nowrap">Export to *.CSV</span>
                </b-button> -->
                <b-button
                  variant="outline-primary"
                  @click="genPaymentReport"
                >
                  <span class="text-nowrap">Export to *.Excel</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
  
        </div>
  
        <b-table
          ref="refPaymentListTable"
          class="position-relative"
          :items="getPayments"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >
  
        <!-- Column: Id -->
        <template #cell(id)="data">
          <!-- <b-link
            :to="{ name: 'apps-invoice-preview', params: { id: data.item.id }}"
            class="font-weight-bold"
          > -->
            <!-- #{{ data.value }} -->
            {{ data.index+1 }}
          <!-- </b-link> -->
        </template>
  
        <!-- Column: Role -->
        <template #cell(type)="data">
            <div class="text-nowrap">
              <feather-icon
                :icon="resolveTypeRoleIcon(data.item.type)"
                size="18"
                class="mr-50"
                :class="`text-primary`"
              />
              <span class="font-weight-bold align-text-top text-capitalize">{{ resolveTypeVariant(data.item.type) }} {{ data.item.type == "tithe" ? ': '+data.item._date.substring(3) : '' }}</span>
            </div>
          </template>
  
        <template #cell(amount)="data">
          <p
            class="font-weight-bolder text-right mb-0 text-primary"
          >
          {{ userData.baseCurrency.sign + formatAmount(data.item.amount) }}
          </p>
        </template>
  
        <template #cell(service)="data">
          <span
            class="font-weight-bold text-"
          >
            {{ data.item.service ? data.item.service.substring(0,1).toUpperCase()+data.item.service.substring(1) : '' }}
          </span>
        </template>

        <template #cell(groups)="data">
          <b-badge
              v-for="group in data.item.groups"
              pill
              :key="group"
              :variant="`primary`"
              class="text-capitalize"
            >
              {{ group }}
            </b-badge>
        </template>
  
          <!-- Column: Role -->
          <template #cell(payment)="data">
            <div class="text-nowrap">
              <!-- <feather-icon
                :icon="resolvePaymentRoleIcon(data.item.role)"
                size="18"
                class="mr-50"
                
              /> -->
              <b-badge :variant="`${resolvePaymentPaymentVariant(data.item.payment)}`">{{ resolvePaymentVariant(data.item.payment) }}</b-badge>
              <!-- <span class="align-text-top text-capitalize">{{ data.item.role }}</span> -->
            </div>
          </template>
  
          <!-- Column: DOB -->
          <template #cell(_paymentDate)="data">
            <div class="text-dark">
              {{   data.item._paymentDate }}
            </div>
          </template>
  
          <!-- Column: Status -->
          <template #cell(status)="data">
            <b-badge
              pill
              :variant="`light-${resolvePaymentStatusVariant(data.item.status)}`"
              class="text-capitalize"
            >
              {{ data.item.status }}
            </b-badge>
          </template>
  
          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
  
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item :to="{ name: 'accounts-payment-preview', params: { id: data.item.id } }">
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Preview</span>
              </b-dropdown-item>

              <!-- <b-dropdown-item @click="approvePayment(data.item)" v-if="data.item.status == 'pending'">
                <feather-icon icon="CheckIcon" />
                <span class="align-middle ml-50">Approve</span>
              </b-dropdown-item> -->
  
              <b-dropdown-item @click="editPayment(data.item)" v-if="data.item.status == 'pending'">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>
  
              <b-dropdown-item @click="send(data.item)" v-if="data.item.status == 'pending'" v-b-modal.cancel-modal>
                <feather-icon icon="XIcon" />
                <span class="align-middle ml-50">Cancel</span>
              </b-dropdown-item>
              
              <b-dropdown-item v-if="$can('read', 'Analytics')" @click="confirmText(data.item)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>
              
              <span v-else>
                <b-dropdown-item v-if="data.item.status == 'pending'" @click="confirmText(data.item)">
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">Delete</span>
                </b-dropdown-item>
              </span>
            </b-dropdown>
          </template>
  
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
  
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Showing {{ (perPage == 'All') ? getPayments.length : dataMeta.from+' to '+dataMeta.to }} of {{ dataMeta.of }} entries</span>
              <span class="text-muted"> &#8212; {{ totalPaymentAmount.toLocaleString() }}</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
  
              <b-pagination
                v-model="currentPage"
                :total-rows="totalPayments+' - '+totalAmount"
                :per-page="(perPage == 'All') ? getPayments.length : perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
  
            </b-col>
  
          </b-row>
        </div>
      </b-card>
  
  
      <!--  -->
      <b-modal
        id="cancel-modal"
        :title="`Cancel Payment ${payment ? ': '+payment.type+' : '+payment.amount : ''}`"
        ok-only
        ok-title="Close"
      > 
      <!-- Body -->
      <validation-observer
            #default="{ handleSubmit }"
            ref="refFormObserver"
          >
          <b-form
              class="p-2"
              @submit.prevent="handleSubmit(onSubmit)"
              @reset.prevent="resetForm"
            >
              <!-- Reason -->
              <validation-provider
                      #default="validationContext"
                      name="Reason"
                      rules="required"
                    >
                <b-form-group
                    label="Reason for cancelation"
                    label-for="reason"
                  >
                    <b-form-textarea
                      id="reason"
                      v-model="payment.reason"
                      trim
                      placeholder="Reason"
                      :state="getValidationState(validationContext)"
                    >
                    </b-form-textarea>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <b-button type="submit">
                Submit
              </b-button>
            </b-form>
        </validation-observer>
        <!-- <b-card-text>
          <h5>Check First Paragraph</h5>
          Oat cake ice cream candy chocolate cake chocolate cake cotton candy dragée apple pie.
          Brownie carrot cake candy canes bonbon fruitcake topping halvah. Cake sweet roll cake cheesecake cookie chocolate cake liquorice.
        </b-card-text> -->
        
      </b-modal>
  
    </div>
  </template>
  
  <script>
  import { getUserData } from '@/auth/utils'
  import {
    BCard, BRow, BCol, BForm, BFormInput, BFormTextarea, BFormGroup, BButton, BTable, BMedia, BAvatar, BLink, BModal, VBModal,
    BBadge, BDropdown, BDropdownItem, BPagination, BFormInvalidFeedback, BFormDatepicker
  } from 'bootstrap-vue'
  import vSelect from 'vue-select'
  import router from '@/router'
  import store from '@/store'
  import { ref, onUnmounted } from '@vue/composition-api'
  import { avatarText } from '@core/utils/filter'
  import PaymentsListFilters from './PaymentListFilters.vue'
  import usePaymentList from './usePaymentList'
  import paymentStoreModule from '../paymentStoreModule'
  import memberStoreModule from '../../../apps/membership/memberStoreModule'
  import calendarStoreModule from '../../../apps/calendar/calendarStoreModule'
  import PaymentListAddNew from '../payment-add-new/PaymentListAddNew.vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required } from '@validations'
  import formValidation from '@core/comp-functions/forms/form-validation'
  import Ripple from 'vue-ripple-directive'
  import Swal from 'sweetalert2'
  import { formatAmount } from '@core/comp-functions/formatAmount'
  
  export default {
    components: {
      PaymentsListFilters,
      PaymentListAddNew,
      BCard,
      BRow,
      BForm,
      BCol,
      BFormInput,
      BFormTextarea,
      BFormGroup,
      BButton,
      BTable,
      BMedia,
      BAvatar,
      BLink,
      BModal, 
      VBModal,
      BBadge,
      BDropdown,
      BDropdownItem,
      BPagination,
      ValidationProvider,
      ValidationObserver,
      BFormInvalidFeedback,
  
      vSelect,
      BFormDatepicker
    },
    
    directives: {
      Ripple,
    },
    data() {
      return {
        required,
      }
    },
    setup() {
    const userData = getUserData()
  
      const blankPaymentData = {
        id: '',
        type: '',
        amount: '',
        reason: ''
      }
  
      const payment = ref(JSON.parse(JSON.stringify(blankPaymentData)))
  
      const send = (data) => {
        //
        payment.value.id = data._id
        payment.value.type = data.type
        payment.value.amount = data.amount
  
      }
  
      const ACCOUNT_RECEIPT_STORE_MODULE_NAME = 'account-payment'
      const MEMBER_APP_STORE_MODULE_NAME = 'app-member'
      const CALENDAR_STORE_MODULE_NAME = 'calendar'
  
      // Register module
      if (!store.hasModule(ACCOUNT_RECEIPT_STORE_MODULE_NAME)) store.registerModule(ACCOUNT_RECEIPT_STORE_MODULE_NAME, paymentStoreModule)
      if (!store.hasModule(MEMBER_APP_STORE_MODULE_NAME)) store.registerModule(MEMBER_APP_STORE_MODULE_NAME, memberStoreModule)
      if (!store.hasModule(CALENDAR_STORE_MODULE_NAME)) store.registerModule(CALENDAR_STORE_MODULE_NAME, calendarStoreModule)
  
      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(ACCOUNT_RECEIPT_STORE_MODULE_NAME)) store.unregisterModule(ACCOUNT_RECEIPT_STORE_MODULE_NAME)
        if (store.hasModule(MEMBER_APP_STORE_MODULE_NAME)) store.unregisterModule(MEMBER_APP_STORE_MODULE_NAME)
        if (store.hasModule(CALENDAR_STORE_MODULE_NAME)) store.unregisterModule(CALENDAR_STORE_MODULE_NAME)
      })
  
      const paymentLocal = ref(JSON.parse(JSON.stringify(payment)))
      const resetPaymentLocal = () => {
        paymentLocal.value = JSON.parse(JSON.stringify(payment))
      }
  
      const {
          refFormObserver,
          getValidationState,
          resetForm,
          clearForm,
      } = formValidation(resetPaymentLocal, payment)
  
      const confirmText = (data) => {
        Swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'error',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            
            delPayment(data)
          }
        })
      }
  
  
      const roleOptions = [
        { label: 'Admin', value: 'admin' },
        { label: 'Author', value: 'author' },
        { label: 'Editor', value: 'editor' },
        { label: 'Maintainer', value: 'maintainer' },
        { label: 'Subscriber', value: 'subscriber' },
      ]
  
      const planOptions = [
        { label: 'Basic', value: 'basic' },
        { label: 'Company', value: 'company' },
        { label: 'Enterprise', value: 'enterprise' },
        { label: 'Team', value: 'team' },
      ]
  
      const statusOptions = [
        { label: 'Approved', value: 'approved' },
        { label: 'Pending', value: 'pending' },
        { label: 'Cancelled', value: 'cancelled' },
      ]
  
      const paymentOptions = [
        { label: 'Cash', value: 'cash' },
        { label: 'Mobile Money', value: 'mobile_money' },
        { label: 'Cheque', value: 'cheque' },
      ]
  
      const typeOptions = [
        { label: 'Expense', value: 'expense' },
        // { label: 'Water', value: 'water' },
        // { label: 'Fuel', value: 'fuel' },
        // { label: 'CableTv', value: 'cableTv' },
        // { label: 'Gas', value: 'gas' },
        // { label: 'Internet', value: 'internet' },
        // { label: 'Trash', value: 'trash' },
        // { label: 'Phone', value: 'phone' },
        // { label: 'Welfare', value: 'welfare' },
        // { label: 'Donations', value: 'donations' },
        // { label: 'Salary', value: 'salary' },
        // { label: 'Project', value: 'project' },
        // { label: 'Miscellaneous', value: 'miscellaneous' },
        // { label: 'Others', value: 'others' },
      ]
  
      const monthOptions = [
        { label: 'January', value: 'Jan' },
        { label: 'February', value: 'Feb' },
        { label: 'March', value: 'Mar' },
        { label: 'April', value: 'Apr' },
        { label: 'May', value: 'May' },
        { label: 'June', value: 'Jun' },
        { label: 'July', value: 'Jul' },
        { label: 'August', value: 'Aug' },
        { label: 'September', value: 'Sep' },
        { label: 'October', value: 'Oct' },
        { label: 'November', value: 'Nov' },
        { label: 'December', value: 'Dec' },
      ]

      const rangeOptions = [
        { label: 'Today', value: 'today' },
        { label: 'Yesterday', value: 'yesterday' },
        // { label: 'This Week', value: 'this week' },
        // { label: 'Previous Week', value: 'previous week' },
        { label: 'This Month', value: 'this month' },
        { label: 'Previous Month', value: 'previous month' },
        { label: 'This Year', value: 'this year' },
        { label: 'Previous Year', value: 'previous year' }
      ]
  
      const {
        getPayments,
        fetchPayments,
        fetchMembers,
        fetchGroups,
        tableColumns,
        perPage,
        currentPage,
        totalPayments,
        totalAmount,
        dataMeta,
        perPageOptions,
        searchQuery,
        fromDate,
        toDate,
        sortBy,
        isSortDirDesc,
        refPaymentListTable,
        refetchData,
  
        // UI
        resolvePaymentStatusVariant,
        resolvePaymentVariant,
        resolvePaymentPaymentVariant,
        resolveTypeVariant,
        resolveTypeRoleIcon,
  
        //
        isAddNewPaymentSidebarActive,
        clearPaymentData,
        editPayment,
        delPayment,
        addPayment,
        updatePayment,
        approvePayment,
        paymentData,
        genPaymentReport,
  
        // Extra Filters
        typeFilter,
        daybornFilter,
        paymentFilter,
        serviceFilter,
        statusFilter,
        monthFilter,
        totalPaymentAmount,
        cancelPayment,
        getAllPaymentCategories,
        fetchPaymentCategories,
        categoryFilter,
        rangeFilter
      } = usePaymentList()
  
      fetchPayments()
      
      fetchPaymentCategories()
  
      fetchMembers()

      fetchGroups()

  
      
      const onSubmit = () => {
        cancelPayment(payment.value)
        clearForm.value()
      }
  
      // 
      setTimeout(()=>{
        
        // edit request
        if (router.currentRoute.params.id) {
          //
          const data = getPayments.value.find(rec => rec.id == router.currentRoute.params.id)
          editPayment(data)
        }
  
        if (router.currentRoute.params.add) {
          //
          isAddNewPaymentSidebarActive.value = true
        }
  
        if (router.currentRoute.params.type && router.currentRoute.params.date && router.currentRoute.params.status) {
          //
          typeFilter.value = router.currentRoute.params.type
          statusFilter.value = router.currentRoute.params.status
          searchQuery.value = router.currentRoute.params.date
          }
        }, 500)
  
      
    
      return {
  
        // Sidebar
        isAddNewPaymentSidebarActive,
  
        getPayments,
        fetchPayments,
        tableColumns,
        perPage,
        currentPage,
        totalPayments,
        totalAmount,
        dataMeta,
        perPageOptions,
        searchQuery,
        fromDate,
        toDate,
        sortBy,
        isSortDirDesc,
        refPaymentListTable,
        refetchData,
        genPaymentReport,
  
        // Filter
        avatarText,
  
        // UI
        resolvePaymentStatusVariant,
        resolvePaymentVariant,
        resolvePaymentPaymentVariant,
        resolveTypeVariant,
        resolveTypeRoleIcon,
  
        typeOptions,
        paymentOptions,
        roleOptions,
        planOptions,
        statusOptions,
        monthOptions,
  
        //
        clearPaymentData,
        editPayment,
        delPayment,
        addPayment,
        updatePayment,
        approvePayment,
        paymentData,
        payment,
        send,
        
        //
        refFormObserver,
        getValidationState,
        resetForm,
        clearForm,
  
        // Extra Filters
        typeFilter,
        paymentFilter,
        statusFilter,
        monthFilter,
        totalPaymentAmount,
        confirmText,
        onSubmit,
        fetchGroups,
        getAllPaymentCategories,
        categoryFilter,
        rangeOptions,
        rangeFilter,
        formatAmount,
        userData
      }
    },
  }
  </script>
  
  <style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
  </style>
  
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>
  