<template>
    <b-sidebar
      id="add-payment-user-sidebar"
      :visible="isAddNewPaymentSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-add-new-payment-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            {{ paymentLocal.id ? 'Update': 'Add' }} Payment
          </h5>
  
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
  
        </div>
  
        <!-- BODY -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
          <validation-provider
                  #default="validationContext"
                  name="type"
                  rules="required"
                >
                  <b-form-group
                    label="Payment Type"
                    label-for="type"
                    :state="getValidationState(validationContext)"
                  >
                    <v-select
                      v-model="paymentLocal.type"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="typeOptions"
                      :clearable="true"
                      :reduce="val => val.value"
                      input-id="type"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <!-- <validation-provider
                  #default="validationContext"
                  name="Expense Category"
                  rules="required"
                > -->
                <b-form-group
                    label="Expense Category"
                    label-for="category"
                  >
                  <v-select
                      v-model="paymentLocal.category"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="categoryOptions"
                      :clearable="false"
                      :reduce="val => val.value"
                      input-id="type"
                      :disabled="paymentLocal.type !== 'expense'"
                    />
  
                    <!-- <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback> -->
                  </b-form-group>
                <!-- </validation-provider> -->
            
            <b-row>
              <!-- Amount -->
              <b-col md="6">
                <validation-provider
                  #default="validationContext"
                  name="Amount"
                  rules="required"
                >
                  <b-form-group
                    label="Amount"
                    label-for="amount"
                  >
                    <b-form-input
                      id="amount"
                      v-model="paymentLocal.amount"
                      autofocus
                      type="number"
                      :state="getValidationState(validationContext)"
                      placeholder=""
                      step="0.01"
                    />
  
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <!-- Type -->
              <b-col md="6">

                <!-- Payment -->
                <b-form-group
                  label="Payment"
                  label-for="payment"
                >
                  <v-select
                    v-model="paymentLocal.payment"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="paymentOptions"
                    :clearable="false"
                    :reduce="val => val.value"
                    input-id="payment"
                  />
                </b-form-group>
                
              </b-col>
            </b-row>
  
            <!-- <b-row> -->
              <!-- By -->
              <!-- <b-col md="6">
                <b-form-group
                    label="By"
                    label-for="by"
                  >
                    <b-form-input
                      id="by"
                      v-model="paymentLocal.requestby"
                      placeholder=""
                      :disabled="paymentLocal.member ? true : paymentLocal.groups.length > 0 ? true : false"
                    />
  
                </b-form-group>
              </b-col> -->

              <!-- <b-col md="6"> -->

              <!-- </b-col> -->

              <!-- <b-col md="6"> -->
                <b-form-group
                  label="Date"
                  label-for="date"
                >
                  <b-form-datepicker
                    v-model="paymentLocal.date"
                    placeholder="YYYY-MM-DD"
                  ></b-form-datepicker>
                </b-form-group>

              <!-- </b-col> -->
              
            <!-- </b-row> -->

            
  
            <b-form-group
              label="Payment For"
              label-for="paymentFor"
            >
              <b-form-textarea
                id="paymentFor"
                v-model="paymentLocal.paymentFor"
                trim
              />
            </b-form-group>
  
            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                Submit
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                Cancel
              </b-button>
            </div>
  
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </template>
  
  <script>
  import {
    BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BFormInvalidFeedback, BButton, BRow, BCol, BFormDatepicker
  } from 'bootstrap-vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { ref, toRefs } from '@vue/composition-api'
  import { required, alphaNum, email } from '@validations'
  import formValidation from '@core/comp-functions/forms/form-validation'
  import Ripple from 'vue-ripple-directive'
  import vSelect from 'vue-select'
  import countries from '@/@fake-db/data/other/countries'
  import useMembersList from '../../../apps/membership/members-list/useMemberList'
  import usePaymentListAddNew from './usePaymentListAddNew'
  
  export default {
    components: {
      BSidebar,
      BForm,
      BFormGroup,
      BFormInput,
      BFormTextarea,
      BFormInvalidFeedback,
      BButton,
      BRow,
      BCol,
      vSelect,
      BFormDatepicker,
  
      // Form Validation
      ValidationProvider,
      ValidationObserver,
    },
    directives: {
      Ripple,
    },
    model: {
      prop: 'isAddNewPaymentSidebarActive',
      event: 'update:is-add-new-payment-sidebar-active',
    },
    props: {
      isAddNewPaymentSidebarActive: {
        type: Boolean,
        required: true,
      },
      roleOptions: {
        type: Array,
        required: true,
      },
      planOptions: {
        type: Array,
        required: true,
      },
      typeOptions:{
        type: Array,
        required: true,
      },
      categoryOptions: {
        type: Array,
        required: true,
      },
      paymentOptions:{
        type: Array,
        required: true,
      },
      paymentData: {
        type: Object
      },
      clearPaymentData: {
        type: Function,
        required: true,
      },
    },
    data() {
      return {
        required,
        alphaNum,
        email,
        countries,
      }
    },
    setup(props, { emit }) {
      
      const clearFormData = ref(null)
        
      const {
        paymentLocal,
        resetPaymentLocal,
        onSubmit, 
        groupsOptions
        
      } = usePaymentListAddNew(toRefs(props), clearFormData, emit)
  
      const { getAllMembers } = useMembersList()
  
      const {
        refFormObserver,
        getValidationState,
        resetForm,
        clearForm,
      } = formValidation(resetPaymentLocal, props.clearPaymentData)
  
      clearFormData.value = clearForm
  
      return {
        
        paymentLocal,
        onSubmit,
  
        refFormObserver,
        getValidationState,
        resetForm,
        getAllMembers,
        groupsOptions
      }
    },
  }
  </script>
  
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  
  #add-new-user-sidebar {
    .vs__dropdown-menu {
      max-height: 200px !important;
    }
  }
  </style>